<script>
import Axios from 'axios'
import debounce from 'lodash/debounce'
import Promise from 'es6-promise'
window.Promise = Promise
export default {
  data() {
    return {
      shopFetcher: {
        name: null,
        target_type: null,
        target_id: null,
        business_category: null,
        query: null,
        prefecture_id: null,
        shop_feature: null,
        event_opening_hour_date: null,
      },
      selectRegionFieldsVisible: false,
      selectQueryFieldsVisible: false,
      candidateRegions: [],
      candidateChains: [],
      changingQuery: null,
      submittable: true,
      candidateRegionsMessageVisible: false,
      changingQueryUpdated: false,
    };
  },
  beforeMount() {
    this.$data.shopFetcher = JSON.parse(this.$el.dataset.form)
    // shop_featureのdataをcategory:conditionに変換
    if (this.$data.shopFetcher.shop_feature) {
      this.$data.shopFetcher.shop_feature = this.$data.shopFetcher.shop_feature.category + ':' + this.$data.shopFetcher.shop_feature.condition
    }
    this.$data.changingQuery = this.$data.shopFetcher.query
    if (!this.$data.shopFetcher.event_opening_hour_date) {
      this.$data.shopFetcher.event_opening_hour_date = ''
    }
  },
  mounted() {
    this.$el.classList.remove('hidden')
    window.addEventListener('click', (event) => {
      this.cancelSettingCandidates()
    }, false);
    this.$el.addEventListener('click', (event) => {
      event.stopPropagation()
    }, false);
    this.validateSubmittable()
  },
  filters: {
    decorateCandidate(candidate) {
      return candidate.replace(/\(チェーン\)/, '')
    },
  },
  computed: {
  },
  watch: {
  },
  methods: {
    showBusinessCategoryChange(event) {
      event.preventDefault()
    },
    updateName: debounce(function(event) {
      this.candidateRegionsMessageVisible = false
      this.shopFetcher.name = event.target.value
      this.cancelChainSettingCandidates()
      this.validateSubmittable()
      if(!this.shopFetcher.name) {
        this.candidateRegions = [];
        this.shopFetcher.target_id = null
        this.shopFetcher.target_type = 'all_region'
        return
      }
      Axios.get('/shop_search_completion_keywords', {
          params: {
            query: this.shopFetcher.name,
            query_type: 'region',
            prefecture_id: this.shopFetcher.prefecture_id,
            event_opening_hour_date: this.shopFetcher.event_opening_hour_date,
          }
        }).
        then((response) => {
          this.candidateRegions = response.data;
          if(this.candidateRegions.length === 0) {
            this.candidateRegionsMessageVisible = true
          }
        }).
        catch((error) => {
          console.error(error);
        });
    }, 200),
    updateQuery: debounce(function(event) {
      this.changingQuery = event.target.value
      this.changingQueryUpdated = false
      if(!this.changingQuery) {
        this.shopFetcher.query = null
        this.candidateChains = [];
        this.validateSubmittable()
        return
      }
      this.cancelRegionSettingCandidates()
      Axios.get('/shop_search_completion_keywords', {
          params: {
            query: this.changingQuery,
            query_type: 'chain',
            prefecture_id: this.shopFetcher.prefecture_id,
            event_opening_hour_date: this.shopFetcher.event_opening_hour_date,
          }
        }).
        then((response) => {
          this.candidateChains = response.data;
          this.changingQueryUpdated = true
        }).
        catch((error) => {
          console.error(error);
        });
    }, 200),
    // region has:
    // {
    //   content: ,
    //   category: ,
    //   relation_id: ,
    //   prefecture_id:
    // }
    setRegion(region) {
      this.shopFetcher.name = region.content
      this.shopFetcher.target_type = region.category
      this.shopFetcher.target_id = region.relation_id
      this.shopFetcher.prefecture_id = region.prefecture_id
      this.candidateRegions = [];
      this.submittable = true
    },
    setChain(chain) {
      this.shopFetcher.query = chain.content
      this.changingQuery = this.shopFetcher.query
      this.candidateChains = [];
      this.submittable = true
      this.changingQueryUpdated = false
    },
    unsubmittable() {
      return !this.submittable
    },
    submitForm(event) {
      if(!this.submittable) {
        event.preventDefault()
      }
    },
    cancelSettingCandidates() {
      this.cancelRegionSettingCandidates()
      this.cancelChainSettingCandidates()
      this.validateSubmittable()
    },
    cancelRegionSettingCandidates() {
      this.candidateRegions = []
      this.candidateRegionsMessageVisible = false
    },
    cancelChainSettingCandidates() {
      this.candidateChains = []
      this.changingQueryUpdated = false
    },
    validateSubmittable() {
      if((this.shopFetcher.name && this.shopFetcher.target_id) || this.shopFetcher.query) {
        this.submittable = true
      } else {
        this.submittable = false
      }
    },
    candidateChainsMessageVisible() {
      return !!this.$data.changingQuery && this.candidateChains.length === 0 && this.changingQueryUpdated
    },
  },
  components: {
  }
}
</script>
