import Vue from 'vue/dist/vue.esm'
import Domready from 'domready'
import ShopFilter from "./components_regions_shop_filter_pc.vue"

Domready(() => {
  let shopFilter = new Vue(ShopFilter)
  shopFilter.$mount(".shop_filter")

  if(process.env.NODE_ENV === "development") {
    window.$shopFilter = shopFilter
  }
});
